/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import WalletContextProvider from './WalletContextProvider'

import Header from "./header"
//import "./layout.css"
import "./new_layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <WalletContextProvider>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />

        <main>{children}</main>

        <footer>
          <div className='container clearfix'>
            <div className='col30 left'>
              <Link to='/' className='logo'></Link>
            </div>
            <div className='col60 right clearfix'>
              <div className='col25 left links-col'>
                <h4>Collections</h4>
                <Link to='/waves-gallery/'>Waves</Link>
                <Link to='/epochs-gallery/'>Epochs</Link>
                <Link to='/populus/'>Populus</Link>
                <Link to='/raw/'>RAW</Link>
                <Link to='/tinyrainboot/'>The Contours of a Dream</Link>
                <Link to='/cyborg-culture/'>Cyborg culture</Link>
              </div>
              <div className='col25 left links-col'>
                <h4>Collectors</h4>
                <Link to='/marketplaces/'>Marketplaces</Link>
                <a href="https://hyperspace.xyz/collection/playground?activeTab=Analytics"
                      target="_blank"
                      rel="noreferrer">Analytics - Waves</a>
                <a href="https://hyperspace.xyz/collection/playgroundepoch?activeTab=Analytics"
                      target="_blank"
                      rel="noreferrer">Analytics - Epochs</a>
              </div>
              <div className='col25 left links-col'>
                <h4>Playground</h4>
                <a href="https://docs.google.com/document/d/1jl9AwLtBoGpk0cWHWg-nbzjsk_ltq75-697-07oxB68/edit#heading=h.jao0j0z3ab6m"
                      target="_blank"
                      rel="noreferrer">FAQ</a>
                <a href="https://forms.gle/DtZU4yHrz9EhyjLLA"
                    target="_blank"
                    rel="noreferrer">Artists</a>
                <Link to="/terms/">Terms</Link>
              </div>
              <div className='col25 left links-col'>
                <h4>Community</h4>
                <a href="https://discord.gg/qnqrAVeHu7"
                    target="_blank"
                    rel="noreferrer">Discord</a>
                <a href="https://twitter.com/playground_sol"
                    target="_blank"
                    rel="noreferrer">Twitter</a>
                <a href="https://playgroundsol.substack.com/welcome"
                    target="_blank"
                    rel="noreferrer">Newsletter</a>
                <a href="https://collector.sh/playground"
                      target="_blank"
                      rel="noreferrer">Playground DAO art collection</a>
              </div>
            </div>
          </div>
        </footer>

    </WalletContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
