import * as React from "react"
import { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndividualWave = ({ data, listings }) => {
    let waveNum = data.node.name.split(' #')[1];
    let liveUrl = '/wave-' + waveNum;
    let mobilePicUrl =  "https://d28a5q050a9bu1.cloudfront.net/images/waves/real-waves/" + waveNum + "+.png";
    let listingInfo = null;

    if (data.node.name in listings) {
        listingInfo = listings[data.node.name];
    }
    return (
        <div className='col30 left collection-item'>
            <Link className='image' to={liveUrl} style={{ backgroundImage: `url(${mobilePicUrl})`}} alt={`Wave #${waveNum}`}></Link>

            <div className='details'>
                <h4>{data.node.name}</h4>
                <Link className='button black detailed-view' to={liveUrl}>View</Link>
                {!!listingInfo &&
                <Link className='listing-link' to={listingInfo.marketURL}>Listed on {listingInfo.market} for {listingInfo.price} SOL</Link>
                }
            </div>
        </div>
    )
}

const PaginatedWaves = ({ data, listings, RenderComponent, pageLimit, dataLimit }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [colorFilterParam, setColorFilterParam] = useState(["All"]);
    const [brightFilterParam, setBrightFilterParam] = useState("All");
    const [alphaFilterParam, setAlphaFilterParam] = useState("All");
    const [saleFilterParam, setSaleFilterParam] = useState("All");
    const [satFilterParam, setSatFilterParam] = useState("All");
    const [speedFilterParam, setSpeedFilterParam] = useState("All");
    const [showFilters, setShowFilters] = React.useState(false);
    let waves = data;
    let [pages] = useState(Math.round(waves.length / dataLimit));
    const [searchQ, setSearchQ] = useState("");

    function applyFilters(allWaves) {
        // # of colors
        let filteredWaves = allWaves.filter((wave) => {
            if (wave.node.attributes.Number_of_colors == colorFilterParam) {
                return wave;
            } else if (colorFilterParam == "All") {
                return wave;
            }
        });

        // search
        filteredWaves = filteredWaves.filter((wave) => {
            if (wave.node.name.toLowerCase().indexOf(searchQ) > -1) {
                return wave;
            }
        });

        // brightness
        filteredWaves = filteredWaves.filter((wave) => {
            let brightness = wave.node.attributes.brightness;

            if (brightFilterParam == "All") {
                return wave;
            } else if (brightFilterParam && brightness >= brightFilterParam.split(',')[0] && brightness < brightFilterParam.split(',')[1]) {
                return wave;
            }
        });

        // alpha
        filteredWaves = filteredWaves.filter((wave) => {
            let alpha = wave.node.attributes.alpha;

            if (alphaFilterParam == "All") {
                return wave;
            } else if (alphaFilterParam && alpha >= alphaFilterParam.split(',')[0] && alpha < alphaFilterParam.split(',')[1]) {
                return wave;
            }
        });

        // saturation
        filteredWaves = filteredWaves.filter((wave) => {
            let saturation = wave.node.attributes.saturation;

            if (satFilterParam == "All") {
                return wave;
            } else if (satFilterParam && saturation >= satFilterParam.split(',')[0] && saturation < satFilterParam.split(',')[1]) {
                return wave;
            }
        });

        // listing status
        filteredWaves = filteredWaves.filter((wave) => {
            if (saleFilterParam == "All") {
                return wave;
            } else if (saleFilterParam === 'Listed-High' || saleFilterParam === 'Listed-Low') {
                if (wave.node.name in listings) {
                    return wave;
                }
            } else if (saleFilterParam === 'Unlisted') {
                if (listings[wave.node.name] == undefined) {
                    return wave;
                }
            }
        });

        // speed
        filteredWaves = filteredWaves.filter((wave) => {
            let speed = wave.node.attributes.speed;

            if (speedFilterParam == "All") {
                return wave;
            } else if (speedFilterParam && speed >= speedFilterParam.split(',')[0] && speed < speedFilterParam.split(',')[1]) {
                return wave;
            }
        });


        if (saleFilterParam === 'Listed-High') {
            filteredWaves = filteredWaves.sort((a, b) => (listings[b.node.name].price) > (listings[a.node.name].price) ? 1 : -1);
        } else if (saleFilterParam === 'Listed-Low') {
            filteredWaves = filteredWaves.sort((a, b) => (listings[a.node.name].price) > (listings[b.node.name].price) ? 1 : -1);
        } else {
            filteredWaves = filteredWaves.sort((a, b) => (parseInt(a.node.name.substring(6))) > parseInt(b.node.name.substring(6)) ? 1 : -1);
        }
        waves = filteredWaves;
        pages = Math.ceil(waves.length / dataLimit);
    }

    function applyColorFilter(color) {
        setColorFilterParam(color);

        setCurrentPage(1);
    }

    function applyBrightFilter(val) {
        setBrightFilterParam(val);
        setCurrentPage(1);
    }

    function applyAlphaFilter(val) {
        setAlphaFilterParam(val);
        setCurrentPage(1);
    }

    function applySaleFilter(val) {
        setSaleFilterParam(val);
        setCurrentPage(1);
    }

    function applySatFilter(val) {
        setSatFilterParam(val);
        setCurrentPage(1);
    }

    function applySpeedFilter(val) {
        setSpeedFilterParam(val);
        setCurrentPage(1);
    }

    function updateSearchQ(query) {
        setSearchQ(query);
        if (currentPage !== 1) {
            setCurrentPage(1);
        }
    }

     function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
        const filterSection = document.getElementById('filters');
        filterSection.scrollIntoView({ behavior: 'smooth' });

     }

     const getPaginatedData = () => {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        applyFilters(data);

         return waves.slice(startIndex, endIndex);
     };

    const getPaginationGroup = () => {
        let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
        return new Array(pages).fill().map((_, idx) => start + idx + 1);
    };

    return (
        <>
            <div className='filters' id='filters'>
                <h2 onClick={() => {setShowFilters(!showFilters);}}>Filter Collection
                    <span>
                      {showFilters ? (
                          <span className='arrow-down'></span>
                      ) : (
                          <span className='arrow-up'></span>
                      )}
                    </span>
                </h2>
                {showFilters && (
                    <div className='filters-list clearfix'>
                        <select className='left col20'
                              onChange={(e) => {
                                  applyColorFilter(e.target.value);
                              }}
                              aria-label="Filter Waves By Color"
                        >
                          <option value="All">Filter By # of Colors</option>
                          <option value="1">1 color</option>
                          <option value="2">2 colors</option>
                          <option value="3">3 colors</option>
                          <option value="4">4 colors</option>
                          <option value="5">5 colors</option>
                        </select>
                        <select className='left col20'
                              onChange={(e) => {
                                  applyBrightFilter(e.target.value);
                              }}
                              aria-label="Filter Waves By Color"
                        >
                          <option value="All">Filter By Brightness</option>
                          <option value={[[80, 85]]}>Less bright</option>
                          <option value={[[85, 95]]}>Medium brightness</option>
                          <option value={[[95, 101]]}>Brightest</option>
                        </select>
                        <select className='left col20'
                              onChange={(e) => {
                                  applyAlphaFilter(e.target.value);
                              }}
                              aria-label="Filter Waves By Alpha"
                        >
                          <option value="All">Filter By Alpha</option>
                          <option value={[[15, 23]]}>More transparent</option>
                          <option value={[[23, 31]]}>Medium alpha</option>
                          <option value={[[31, 41]]}>More opaque</option>
                        </select>
                        <select className='left col20'
                              onChange={(e) => {
                                  applySatFilter(e.target.value);
                              }}
                              aria-label="Filter Waves By Saturation"
                        >
                          <option value="All">Filter By Saturation</option>
                          <option value={[[80, 87]]}>Less saturation</option>
                          <option value={[[87, 93]]}>Medium saturation</option>
                          <option value={[[93, 101]]}>More saturation</option>
                        </select>
                        <select className='left col20'
                              onChange={(e) => {
                                  applySpeedFilter(e.target.value);
                              }}
                              aria-label="Filter Waves By Speed"
                        >
                          <option value="All">Filter By Speed</option>
                          <option value={[[0.5, 0.7]]}>Slower</option>
                          <option value={[[0.7, 0.9]]}>Medium speed</option>
                          <option value={[[0.9, 1.21]]}>Faster</option>
                        </select>

                        <select className='left col20'
                              onChange={(e) => {
                                  applySaleFilter(e.target.value);
                              }}
                              aria-label="Filter Waves By Listing Status"
                        >
                          <option value="All">Filter By Listing Status</option>
                          <option value="Listed-High">Currently listed (High --> Low)</option>
                          <option value="Listed-Low">Currently listed (Low --> High)</option>
                          <option value="Unlisted">Not currently for sale</option>
                        </select>


                        <input className='left col20'
                             type="search"
                             name="search-form"
                             placeholder="Search for waves by number"
                             value={searchQ}
                          /*
                          // set the value of our useState searchQ
                          //  anytime the user types in the search box
                          */
                             onChange={(e) => updateSearchQ(e.target.value)}
                        />
                    </div>
                )}
            </div>

            <div>

                <div className='collections-list'>
                    {getPaginatedData().map((d, idx) => (
                        <RenderComponent key={idx} data={d} listings={listings} />
                    ))}
                </div>

                <div className='pagination'>

                    {/* show page numbers */}
                    {getPaginationGroup().map((item, index) => (
                        <div className={`button ${currentPage === item ? "black" : ""}`}
                        active={currentPage === item}
                        key={index}
                        onClick={changePage}
                        pageNum={item}
                        >
                        <span>{item}</span>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

const WaveGallery = ({ data }) => {
    const waves = data.allSpecificWavesJson.edges.sort((a, b) => (parseInt(a.node.name.substring(6))) > parseInt(b.node.name.substring(6)) ? 1 : -1);

    const solanartListingsURL = 'https://qzlsklfacc.medianetwork.cloud/nft_for_sale?collection=playground';
    const cheaperSolanartListingsURL = 'https://qzlsklfacc.medianetwork.cloud/get_nft?collection=playground&page=1&limit=100&order=price-DESC&fits=any&trait=&search=&min=0&max=0&listed=true&ownedby=&attrib_count=&bid=all';
    const alphaListingsURL = 'https://apis.alpha.art/api/v1/collection';
    const alphaPayload = {
        collectionId: 'playground-waves',
        orderBy: 'PRICE_LOW_TO_HIGH',
        status: ['BUY_NOW'],
        traits: []
    };
    const magicEdenURL = 'https://api-mainnet.magiceden.io/rpc/getListedNFTsByQuery?q=%7B%22%24match%22%3A%7B%22collectionSymbol%22%3A%22playground_waves%22%7D%2C%22%24sort%22%3A%7B%22takerAmount%22%3A1%2C%22createdAt%22%3A-1%7D%2C%22%24skip%22%3A0%2C%22%24limit%22%3A20%7D';

    const [listingsData, setListingsData] = useState({});

    React.useEffect(() => {
        getListingsDataWithFetch();
    }, []);

    const getListingsDataWithFetch = async () => {
        let dictionaryListings = [];
        /*
            Throws 502 (Bad Gateway)

        const response = await fetch(solanartListingsURL);
        const jsonData = await response.json();
        // We want to make the Wave name ("Wave #235") the key
        let dictionaryListings = jsonData.reduce((a, listing) => ({...a, [listing.name]: {...listing, market: 'Solanart', marketURL: 'https://solanart.io/collections/playground'}}), {});

        const secondPageSolanartResponse =  await fetch(cheaperSolanartListingsURL);
        const cheaperSolanartListingsJson = await secondPageSolanartResponse.json();
        console.log(cheaperSolanartListingsJson);
        (cheaperSolanartListingsJson.items || []).forEach(wave => {
            dictionaryListings[wave.name] = {
                market: 'Solanart',
                marketURL: 'https://solanart.io/collections/playground',
                price: wave.price
            };
        });*/

        const alphaResponse = await fetch(alphaListingsURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(alphaPayload),
        })

        const alphaJson = await alphaResponse.json();
        (alphaJson.tokens || []).forEach(wave => {
            dictionaryListings[wave.title] = {
                market: 'Alpha.art',
                marketURL: 'https://alpha.art/collection/playground-waves',
                price: +(parseInt(wave.price) * 0.000000001).toFixed(2)
            };
        });

        const magicResponse = await fetch(magicEdenURL);
        const magicJson = await magicResponse.json();

        (magicJson.results || []).forEach(wave => {
            dictionaryListings[wave.title] = {
                market: 'Magic Eden',
                marketURL: 'https://magiceden.io/marketplace?collection_symbol=playground_waves',
                price: wave.price
            };
        });

        setListingsData(dictionaryListings);
    };

  return (
    <Layout>
        <Seo title="Playground - Waves Gallery View" />

        <section className='section-title white collection'>
            <div className='container clearfix'>
                <div className='col50 left info'>
                    <div className='clearfix tags'>
                        <Link to='/collections/' className='button black left'>Collection</Link>
                        <a className='button black left'>1000 minted</a>
                    </div>
                    <h1>Waves <span>by Mccannatron</span></h1>
                    <p className='big'>Waves is a moving generative art collection created by Mccannatron. Waves is a collection of 1000 unique art pieces inspired by the fluidity of the crypto markets including Bollinger Bands, wave theory, and moon math charts.</p>
                </div>
                <div className='col50 left image'>
                    <img src="https://d28a5q050a9bu1.cloudfront.net/images/69-banner-smaller.png" />
                </div>
            </div>
        </section>

        <section className='collection-detail'>
            <div className='container clearfix'>
                <PaginatedWaves
                    data={waves}
                    listings={listingsData}
                    RenderComponent={IndividualWave}
                    pageLimit={11}
                    dataLimit={99}
                />
            </div>
        </section>



    </Layout>
  )
}

export const query = graphql`
    query GalleryQuery {
        allSpecificWavesJson {
        edges {
            node {
              id
              attributes {
                  hash_id
                  Number_of_colors
                  brightness
                  speed
                  saturation
                  alpha
              }
              name
            }
        }
        }
    }
`

export default WaveGallery