import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'

const Header = ({ siteTitle }) => {

    const [showMenu, setShowMenu] = React.useState(false);
    return (
        <header className='top-header'>
            <div className='container clearfix'>
                <div className='col25 left'>
                    <Link to='/' className='logo'></Link>
                </div>
                <a className='mobile-menu' onClick={() => {setShowMenu(true);}}></a>
                <div className={`col50 left ${showMenu ? "active" : ""}`} >
                    <ul>
                        <li><Link to="/collections/">Collections</Link></li>
                        <li><Link to="/dao/">About</Link></li>
                    </ul>
                    <a className='mobile-close' onClick={() => {setShowMenu(false);}}></a>
                </div>
                <div className='col25 right'>
                    <a className='button connect-wallet hidden'>Connect Wallet</a>
                    <WalletMultiButton />
                </div>
            </div>
        </header>
    )

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
